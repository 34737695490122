var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './SQLStatement.css';
var SQLStatement = function (_a) {
    var statement = _a.statement, executeSQLStatement = _a.executeSQLStatement, _b = _a.SQLExecuted, SQLExecuted = _b === void 0 ? true : _b;
    var copyToClipboard = function () {
        navigator.clipboard.writeText(statement)
            .then(function () {
            alert('Code copied to clipboard!');
        })
            .catch(function (err) {
            console.error('Failed to copy code:', err);
        });
    };
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "code-container" }, { children: [_jsx("button", __assign({ className: "copy-button", onClick: copyToClipboard }, { children: "Copy Code" })), !SQLExecuted && (_jsx("button", __assign({ className: "execute-button", onClick: executeSQLStatement }, { children: "Execute SQL" }))), statement ? (_jsx("pre", __assign({ style: { backgroundColor: "black" } }, { children: _jsx("code", { children: statement }) }))) : (_jsx("p", { children: "No SQL query found!" }))] })) }));
};
export default SQLStatement;
