var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SQLStatement from './SQLStatement';
import './ChatMessage.css';
import { IconFileCode } from '@cimpress-technology/react-streamline-icons/lib';
var OnlySQLStatementTab = function (_a) {
    var message = _a.message, executeSQLStatement = _a.executeSQLStatement;
    // Check if SQL statement exists
    var hasSqlQuery = message.sql;
    // Use a ref to store the promptId locally for each instance of the component
    var localPromptId = message.prompt_id;
    var handleButtonClick = function () {
        executeSQLStatement(null, localPromptId, hasSqlQuery); // Call executeSQLStatement with the local promptId
    };
    return (_jsxs("div", __assign({ className: "chat-message-container" }, { children: [_jsx("br", {}), _jsx("h4", { children: "Only SQL Statement" }), _jsx("br", {}), hasSqlQuery && (_jsxs("div", __assign({ className: "tab-content" }, { children: [_jsxs("button", __assign({ className: "tab disable", onClick: handleButtonClick }, { children: [_jsx(IconFileCode, { style: { color: '#E17055', height: "40px" }, className: "tab-icon", weight: "fill" }), _jsx("span", __assign({ className: "tab-text" }, { children: "SQL Query" }))] })), _jsx("div", __assign({ className: "sql-query-message" }, { children: _jsx(SQLStatement, { statement: message.sql, executeSQLStatement: handleButtonClick, SQLExecuted: false }) }))] }))), !hasSqlQuery && (_jsx("div", __assign({ className: "no-query-message" }, { children: _jsx("p", { children: "No SQL query available." }) })))] })));
};
export default OnlySQLStatementTab;
