var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import './TableComponent.css';
var TableComponent = function (_a) {
    var columns = _a.columns, data = _a.data;
    var _b = useState(''), searchTerm = _b[0], setSearchTerm = _b[1]; // State to track the search input
    // Use useMemo to prevent recalculating filteredData on every render
    var filteredData = useMemo(function () {
        if (!data)
            return []; // Return an empty array if `data` is null or undefined
        return data.filter(function (row) {
            return Object.values(row).some(function (value) {
                return value !== null && value !== undefined &&
                    String(value).toLowerCase().includes(searchTerm.toLowerCase());
            });
        });
    }, [data, searchTerm]);
    var _c = useTable({ columns: columns, data: filteredData }, // Pass filteredData to useTable
    useSortBy // Enable sorting
    ), getTableProps = _c.getTableProps, getTableBodyProps = _c.getTableBodyProps, headerGroups = _c.headerGroups, rows = _c.rows, prepareRow = _c.prepareRow;
    return (_jsxs("div", __assign({ className: "table-container" }, { children: [_jsx("input", { type: "text", placeholder: "Search...", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); }, className: "search-input" }), _jsxs("table", __assign({}, getTableProps(), { className: "table" }, { children: [_jsx("thead", { children: headerGroups.map(function (headerGroup) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) { return (_jsxs("th", __assign({}, column.getHeaderProps(column.getSortByToggleProps()), { className: column.isSorted
                                    ? column.isSortedDesc
                                        ? 'sort-desc'
                                        : 'sort-asc'
                                    : '' }, { children: [column.render('Header'), _jsx("span", { children: column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '' })] }))); }) }))); }) }), _jsx("tbody", __assign({}, getTableBodyProps(), { children: rows.map(function (row) {
                            prepareRow(row);
                            return (_jsx("tr", __assign({}, row.getRowProps(), { children: row.cells.map(function (cell) { return (_jsx("td", __assign({}, cell.getCellProps(), { children: cell.value }))); }) })));
                        }) }))] }))] })));
};
export default TableComponent;
