var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// components/ChatMessage.tsx
import { memo } from 'react';
import SQLResultMessage from './messages/SQLResultMessage';
import SuggestionsMessage from './messages/SuggestionsMessage';
import PreChatMessage from './messages/PreChatMessage';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import OnlySQLStatementTab from './OnlySQLStatementTab';
import UserMessage from './messages/UserMessage';
import PsychologyIcon from '@mui/icons-material/Psychology';
function getMessageClassName(type) {
    switch (type) {
        case 'user':
            return 'user-message';
        case 'bot':
            return 'bot-message';
        case 'complete':
            return 'complete-message';
        case 'pre_chat': // Add case for pre_chat
            return 'pre-chat-message'; // Class name for pre_chat messages
        default:
            return '';
    }
}
var ChatMessage = memo(function (_a) {
    var message = _a.message, executeSQLStatement = _a.executeSQLStatement, handleSubmitWrapper = _a.handleSubmitWrapper, setPromptId = _a.setPromptId, setSqlQuery = _a.setSqlQuery;
    if (message.type === 'sql_result') {
        return _jsx(SQLResultMessage, { message: message, executeSQLStatement: executeSQLStatement });
    }
    else if (message.type === 'suggestions') {
        return _jsx(SuggestionsMessage, { suggestions: message.suggestions });
    }
    else if (message.type === 'sql_query') {
        return _jsx(OnlySQLStatementTab, { message: message, executeSQLStatement: executeSQLStatement });
    }
    else if (message.type === 'pre_chat') {
        return _jsx(PreChatMessage, { message: message, handleSubmitWrapper: handleSubmitWrapper });
    }
    return (_jsxs("div", __assign({ className: "chat-message ".concat(getMessageClassName(message.type)) }, { children: [message.type === 'user' ? (_jsx(PersonIcon, { className: "message-icon", color: "primary" })) : message.type === 'bot' ? (_jsx(SmartToyIcon, { color: "primary", className: "message-icon" })) : message.type === 'complete' ? (_jsxs("div", __assign({ className: "icon-text-container" }, { children: [_jsx(PsychologyIcon, { color: "primary", className: "message-icon", style: { height: '40px', width: '40px' } }), _jsx("h4", { children: _jsx("b", { children: "Smart Insights:" }) })] }))) : null, message.type === 'user' ? (_jsx(UserMessage, { text: message.text, promptId: message.prompt_id }) // Use the UserMessage component
            ) : message.type === 'complete' ? (_jsx("p", { children: message.text })) : (message.text)] })));
});
export default ChatMessage;
