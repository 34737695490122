var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HistoryIcon from '@mui/icons-material/History';
var PreChatMessage = function (_a) {
    var message = _a.message, handleSubmitWrapper = _a.handleSubmitWrapper;
    var handleTryItClick = function () {
        var prompt = message.text;
        handleSubmitWrapper(null, prompt);
    };
    return (_jsxs("div", __assign({ className: "chat-message pre-chat-message fade-in" }, { children: [_jsx(HistoryIcon, { className: "message-icon", color: "primary" }), _jsx("span", { children: message.text }), _jsx("button", __assign({ className: "try-it-button", onClick: handleTryItClick }, { children: "Try it." }))] })));
};
export default PreChatMessage;
