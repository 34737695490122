// src/api.ts
// const HOST = "http://localhost:5010"
export var formatValue = function (value) {
    // Handle numbers with commas by removing commas
    if (typeof value === 'string' && value.includes(',')) {
        value = value.replace(/,/g, '');
    }
    // Handle datetime values in "YYYY-MM-DD HH:mm:ss.SSSSSS" format
    if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d+$/.test(value)) {
        return value.split('.')[0]; // Trim milliseconds
    }
    // Handle standard date values **before** treating it as a number
    var date = new Date(value);
    if (typeof value === 'string' && !isNaN(date.getTime()) && value.includes('-')) {
        if (value.includes('T')) {
            return value.split('.')[0]; // Trim milliseconds from ISO format
        }
        else {
            return date.toLocaleDateString('en-CA'); // 'YYYY-MM-DD'
        }
    }
    var parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && value.toString().trim() !== '') {
        // Example: value = "1124.00" -> returns "1124"
        if (value.includes('.') && /^-?\d+(\.\d*0+)?$/.test(value)) {
            return parsedValue.toString();
        }
        // Example: value = "1124.190294" -> returns "1124.19"
        if (!Number.isInteger(parsedValue)) {
            return parsedValue.toFixed(2); // Format with 2 decimal places
        }
        return parsedValue.toString();
    }
    return value; // Return original value if not a date or number
};
export var formatSqlResult = function (sqlResult) {
    return sqlResult.map(function (row) {
        var formattedRow = {}; // Explicitly typing the object
        for (var key in row) {
            if (row.hasOwnProperty(key)) {
                formattedRow[key] = formatValue(row[key]);
            }
        }
        return formattedRow;
    });
};
export var sanitizeString = function (inputString) {
    return inputString.replace(/[\'\"\/\\;]/g, "");
};
export var processSQLQuery = function (sql_query, // SQL query string (optional)
addMessage, // Function to add message
prompt_id) {
    addMessage({
        type: 'sql_query',
        columns: null,
        data: null,
        sql: sql_query || '',
        prompt_id: prompt_id
    });
};
export var formatSQLQuery = function (message) {
    var sqlQuery = message.content
        .map(function (item) { return item.statement || ''; }) // Handle completion message
        .filter(Boolean) // Remove empty strings
        .join(' ');
    if (sqlQuery) {
        return sqlQuery;
    }
    return null;
};
export var handleSQLResult = function (sql_result, setColumns) {
    if (sql_result && sql_result.length > 0) {
        var keys = Object.keys(sql_result[0]);
        var dynamicColumns = keys.map(function (key) { return ({
            Header: key,
            accessor: key
        }); });
        setColumns(dynamicColumns);
        var formatedata = formatSqlResult(sql_result);
        return { dynamicColumns: dynamicColumns, formatedata: formatedata };
    }
    return null;
};
export var processSQLResult = function (sql_result, // Raw SQL result
sql_query, // SQL query string (optional)
setColumns, // Function to set dynamic columns
addMessage) {
    var res = handleSQLResult(sql_result, setColumns);
    // Destructure the dynamicColumns and formatedata from the result, if available
    var dynamicColumns = res === null || res === void 0 ? void 0 : res.dynamicColumns;
    var formatedata = res === null || res === void 0 ? void 0 : res.formatedata;
    // If dynamicColumns are available, add an SQL result message
    addMessage({
        type: 'sql_result',
        columns: dynamicColumns,
        data: formatedata,
        sql: sql_query || '', // Use the provided SQL query or fallback to an empty string
    });
};
