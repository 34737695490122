var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import SmartToyIcon from '@mui/icons-material/SmartToy';
var LoadingMessage = React.memo(function (_a) {
    var isLoading = _a.isLoading;
    var _b = useState(0), loadingTime = _b[0], setLoadingTime = _b[1];
    useEffect(function () {
        if (!isLoading) {
            return; // Exit effect early if not loading
        }
        setLoadingTime(0); // Reset time when loading starts
        var interval = setInterval(function () {
            setLoadingTime(function (prevTime) { return prevTime + 1; });
        }, 1000);
        return function () { return clearInterval(interval); };
    }, [isLoading]);
    return (_jsxs("div", __assign({ className: "chat-message loading-message bot-message" }, { children: [_jsx(SmartToyIcon, { color: "primary", className: "message-icon" }), "...awaiting response (", loadingTime, " seconds)"] })));
});
export default LoadingMessage;
