var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import './ChatForm.css';
var ChatForm = function (_a) {
    var handleSubmitWrapper = _a.handleSubmitWrapper, prompt = _a.prompt, fileName = _a.fileName, setFileName = _a.setFileName, fileOptions = _a.fileOptions, isLoading = _a.isLoading, setHasStartedChat = _a.setHasStartedChat;
    var textareaRef = useRef(null);
    var _b = useState(prompt), localPrompt = _b[0], setLocalPrompt = _b[1];
    var adjustHeight = function () {
        var textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = "".concat(textarea.scrollHeight, "px");
        }
    };
    // useEffect(() => {
    //   adjustHeight();
    // }, [localPrompt]);
    useEffect(function () {
        // Automatically set the fileName if the corresponding file option exists
        var defaultFile = fileOptions.find(function (file) { return file === 'FULFILLMENT_ORDER_ITEMS.yaml'; });
        if (defaultFile && !fileName) {
            setFileName(defaultFile); // Set fileName to the object
        }
    }, [fileOptions, fileName, setFileName]);
    // Effect to change hasStartedChat state when fileName changes
    useEffect(function () {
        if (fileName) {
            setHasStartedChat(true); // Set to true when a file is selected
        }
        else {
            setHasStartedChat(false); // Set to false if no file is selected
        }
    }, [fileName, setHasStartedChat]);
    var handleKeyPress = function (e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            // Call handleSubmitWrapper with the localPrompt when the Enter key is pressed
            handleSubmitWrapper(null, localPrompt);
            // Optionally, clear the local prompt after submission
            setLocalPrompt('');
        }
    };
    var handleSubmit = function (e) {
        e.preventDefault(); // Prevent default form submission
        // Call handleSubmitWrapper with the localPrompt when the form is submitted
        handleSubmitWrapper(null, localPrompt);
        // Optionally, clear the local prompt after submission
        setLocalPrompt('');
    };
    var handleChange = function (e) {
        setLocalPrompt(e.target.value);
        adjustHeight();
    };
    var handleFileChange = function (e) {
        var selectedFileName = e.target.value;
        var selectedFile = fileOptions.find(function (file) { return file === selectedFileName; });
        if (selectedFile) {
            setFileName(selectedFile); // Set fileName to the object
        }
        else {
            setFileName(""); // If no file is selected, set to null
        }
    };
    return (_jsxs("form", __assign({ className: "chat-input-container", onSubmit: handleSubmit }, { children: [_jsxs("div", __assign({ className: "textarea-container" }, { children: [_jsx("textarea", { ref: textareaRef, placeholder: "Type your message here...", value: localPrompt, onChange: handleChange, onKeyPress: handleKeyPress, className: "chat-input" }), _jsx(IconButton, __assign({ type: "submit", className: "submit-button", disabled: isLoading, "aria-label": "send" }, { children: isLoading ? _jsx(CircularProgress, { size: 24 }) : _jsx(SendIcon, {}) }))] })), _jsxs("select", __assign({ value: fileName.replace('.yaml', '') || '', onChange: handleFileChange, className: "file-select", disabled: !fileOptions.length }, { children: [_jsx("option", __assign({ value: "", disabled: true }, { children: fileOptions.length ? 'Select a dataset' : 'Loading files...' })), fileOptions.map(function (file) { return (_jsxs("option", __assign({ value: file.replace('.yaml', '') }, { children: [file.replace('.yaml', ''), " "] }), file)); })] }))] })));
};
export default ChatForm;
