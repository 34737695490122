var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './ChartComponent.css';
// Register necessary chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);
var ChartComponent = function (_a) {
    var data = _a.data, initialChartType = _a.chartType, metadata = _a.metadata;
    var _b = useState(initialChartType), chartType = _b[0], setChartType = _b[1];
    var _c = useState(''), xColumn = _c[0], setXColumn = _c[1];
    var _d = useState(''), yColumn = _d[0], setYColumn = _d[1];
    // Temporary state to hold the selections
    var _e = useState(''), tempXColumn = _e[0], setTempXColumn = _e[1];
    var _f = useState(''), tempYColumn = _f[0], setTempYColumn = _f[1];
    var columns = data.length > 0 ? Object.keys(data[0]) : [];
    // Set default values for X and Y columns when component mounts or data changes
    useEffect(function () {
        if (columns.length >= 2 && !xColumn && !yColumn) { // Check if xColumn and yColumn are not already set
            setXColumn(columns[0]); // Default to first column for X-Axis
            setYColumn(columns[1]); // Default to second column for Y-Axis
            setTempXColumn(columns[0]); // Set temp to default as well
            setTempYColumn(columns[1]); // Set temp to default as well
        }
    }, [columns, xColumn, yColumn]); // Include xColumn and yColumn in dependency array
    var chartData = {
        labels: data.map(function (item) { return item[xColumn]; }),
        datasets: [
            {
                label: yColumn,
                data: data.map(function (item) { return item[yColumn]; }),
                backgroundColor: function (ctx) {
                    var chart = ctx.chart;
                    var chartCtx = chart.ctx, chartArea = chart.chartArea;
                    if (!chartArea) {
                        return null;
                    }
                    var gradient = chartCtx.createLinearGradient(0, 0, 0, chartArea.bottom);
                    gradient.addColorStop(0, 'rgba(75, 192, 192, 0.7)');
                    gradient.addColorStop(1, 'rgba(153, 102, 255, 0.5)');
                    return gradient;
                },
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                hoverBackgroundColor: 'rgba(255, 99, 132, 0.7)',
                hoverBorderColor: 'rgba(255, 99, 132, 1)',
                hoverBorderWidth: 3,
            },
        ],
    };
    var options = {
        responsive: true,
        animation: {
            duration: 1000,
            easing: 'easeOutBounce',
        },
        plugins: {
            legend: {
                labels: {
                    color: '#4B5563',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    padding: 20,
                },
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                titleFont: { size: 16, weight: 'bold' },
                bodyFont: { size: 14 },
                padding: 10,
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#4B5563',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: '#4B5563',
                    stepSize: 20,
                },
                grid: {
                    color: 'rgba(200, 200, 200, 0.3)',
                },
            },
        },
    };
    var handleChartTypeChange = function (event) {
        setChartType(event.target.value);
    };
    var handleXChange = function (event) {
        var selectedValue = event.target.value;
        setTempXColumn(selectedValue);
        setXColumn(selectedValue); // Update X-Axis immediately
    };
    var handleYChange = function (event) {
        var selectedValue = event.target.value;
        setTempYColumn(selectedValue);
        setYColumn(selectedValue); // Update Y-Axis immediately
    };
    return (_jsxs("div", __assign({ className: "chart-settings-container" }, { children: [_jsxs("div", __assign({ className: "axis-selectors" }, { children: [_jsxs("label", __assign({ className: "axis-label" }, { children: ["X-Axis:", _jsxs("select", __assign({ value: tempXColumn, onChange: handleXChange, className: "axis-dropdown" }, { children: [_jsx("option", __assign({ value: "", disabled: true }, { children: "Select X-axis" })), columns.map(function (col) { return (_jsx("option", __assign({ value: col }, { children: col }), col)); })] }))] })), _jsxs("label", __assign({ className: "axis-label" }, { children: ["Y-Axis:", _jsxs("select", __assign({ value: tempYColumn, onChange: handleYChange, className: "axis-dropdown" }, { children: [_jsx("option", __assign({ value: "", disabled: true }, { children: "Select Y-axis" })), columns.map(function (col) { return (_jsx("option", __assign({ value: col }, { children: col }), col)); })] }))] })), _jsxs("label", __assign({ className: "axis-label" }, { children: ["Chart Type:", _jsxs("select", __assign({ value: chartType, onChange: handleChartTypeChange, className: "chart-type-dropdown" }, { children: [_jsx("option", __assign({ value: "bar" }, { children: "Bar" })), _jsx("option", __assign({ value: "line" }, { children: "Line" }))] }))] }))] })), _jsx(ResizableBox, __assign({ className: "chart-container", width: metadata.width, height: metadata.height, minConstraints: [700, 300], maxConstraints: [1000, 600], axis: "both", resizeHandles: ['se'] }, { children: chartType === 'bar' ? (_jsx(Bar, { data: chartData, options: options })) : (_jsx(Line, { data: chartData, options: options })) }))] })));
};
export default ChartComponent;
