var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Switch from 'react-switch';
import './ChatHeader.css';
var ChatHeader = function (_a) {
    var isCompleteEnabled = _a.isCompleteEnabled, setIsCompleteEnabled = _a.setIsCompleteEnabled, isOnySQLEnabled = _a.isOnySQLEnabled, setIsOnySQLEnabled = _a.setIsOnySQLEnabled, columns = _a.columns, setFilter = _a.setFilter;
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "chat-header" }, { children: _jsxs("div", __assign({ className: "switches-container" }, { children: [_jsxs("label", __assign({ className: "switch-label", "data-tooltip": "Allow LLM directly answers on data." }, { children: [_jsx("span", { children: "Smart Insights" }), _jsx(Switch, { onChange: function () { return setIsCompleteEnabled(!isCompleteEnabled); }, checked: isCompleteEnabled, onColor: "#90ee90", onHandleColor: "#2693e6", handleDiameter: 15, uncheckedIcon: false, checkedIcon: false, height: 20, width: 48 })] })), _jsxs("label", __assign({ className: "switch-label", "data-tooltip": "Generates only SQL" }, { children: [_jsx("span", { children: "Generate SQL" }), _jsx(Switch, { onChange: function () { return setIsOnySQLEnabled(!isOnySQLEnabled); }, checked: isOnySQLEnabled, onColor: "#90ee90", onHandleColor: "#2693e6", handleDiameter: 15, uncheckedIcon: false, checkedIcon: false, height: 20, width: 48 })] }))] })) })) }));
};
export default ChatHeader;
