var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { PlatformHeader } from './components/header';
import ChatMCPComponent from './components/ChatMCP';
import { AuthContext } from './authContext';
import './App.css';
// Main application component
var App = function () {
    var authContext = useContext(AuthContext);
    var props = useMemo(function () { return ({
        accessToken: authContext.accessToken
    }); }, [authContext.accessToken]);
    return (_jsxs("div", __assign({ className: "app-container" }, { children: [_jsx(PlatformHeader, {}), _jsx(ChatMCPComponent, __assign({}, props))] })));
};
export default App;
