var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef, useCallback } from 'react';
import '../App.css';
import ChatForm from '../components/ChatForm';
import ChatMessage from './ChatMessage'; // Add this line
import ChatHeader from './ChatHeader';
import { ApiService } from '../services/ApiService';
import { v4 as uuidv4 } from 'uuid';
import { processSQLQuery, sanitizeString, formatSQLQuery, processSQLResult } from '../ApiUtil';
import LoadingMessage from './LoadingMessage';
var check_complete = function (accessToken, startTime, prompt, fileName, prompt_id) { return __awaiter(void 0, void 0, void 0, function () {
    var resultFromComplete, formattedText, endComplete, loadingTimeToComplete, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ApiService.checkComplete(accessToken, prompt, fileName, prompt_id)];
            case 1:
                resultFromComplete = _a.sent();
                // If resultFromComplete is null, it means "FETCH THE DATA" was detected, so no need to process further
                if (resultFromComplete) {
                    formattedText = resultFromComplete.split('\n').map(function (line, index) { return (_jsx("p", { children: line }, index)); });
                    endComplete = performance.now();
                    loadingTimeToComplete = Math.round((endComplete - startTime) / 1000);
                    return [2 /*return*/, (_jsxs("div", { children: [formattedText, _jsxs("p", { children: ["(", loadingTimeToComplete, "s)"] })] }))];
                }
                return [2 /*return*/, null]; // return null if there's no valid result to display
            case 2:
                error_1 = _a.sent();
                console.error("Error in checkComplete:", error_1);
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); };
// Helper function to handle form submission and API call
var handleSubmit = function (prompt, fileName, accessToken, addMessage, setIsLoading, setColumns, setData, isCompleteEnabled, setIsCompleteEnabled, isOnySQLEnabled, setSqlQuery, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var promptId, startTime, formattedText_with_time, result, endTime, loadingTime, message, sql_result, botMessage, completionMessage, botMessageWithTime, completionMessageWithTime, suggestions, sql_query, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                promptId = uuidv4();
                if (!accessToken) {
                    console.error("Access token is missing");
                    return [2 /*return*/];
                }
                setIsLoading(true);
                startTime = performance.now();
                addMessage({ type: 'user', text: prompt, prompt_id: promptId });
                prompt = sanitizeString(prompt) + " " + filters;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 8, 9, 10]);
                if (!isCompleteEnabled) return [3 /*break*/, 3];
                return [4 /*yield*/, check_complete(accessToken, startTime, prompt, fileName, promptId)];
            case 2:
                formattedText_with_time = _a.sent();
                if (formattedText_with_time) {
                    addMessage({ type: 'complete', text: formattedText_with_time });
                    return [2 /*return*/];
                }
                _a.label = 3;
            case 3:
                result = void 0;
                if (!isOnySQLEnabled) return [3 /*break*/, 5];
                return [4 /*yield*/, ApiService.getSQL(prompt, fileName, accessToken, isCompleteEnabled, promptId)];
            case 4:
                result = _a.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, ApiService.submitPrompt(accessToken, prompt, fileName, isCompleteEnabled, promptId)];
            case 6:
                result = _a.sent();
                _a.label = 7;
            case 7:
                endTime = performance.now();
                loadingTime = Math.round((endTime - startTime) / 1000);
                message = result.message, sql_result = result.sql_result;
                botMessage = message.content
                    .map(function (item) {
                    return item.text || ''; // Handle regular text messages
                })
                    .join(' ')
                    .replace(/__/g, '');
                completionMessage = message.content
                    .map(function (item) {
                    if (item.complete) {
                        return item.complete;
                    }
                    return ''; // Skip if no completion data
                })
                    .filter(Boolean) // Remove empty strings
                    .join(' ');
                botMessageWithTime = "".concat(botMessage, " (").concat(loadingTime, "s)");
                completionMessageWithTime = "".concat(completionMessage, " (").concat(loadingTime, "s)");
                // Add bot message first
                if (botMessage) {
                    addMessage({ type: 'bot', text: botMessageWithTime });
                }
                // If there's a completion message, add it separately as a new message type
                if (completionMessage) {
                    addMessage({ type: 'complete', text: completionMessageWithTime });
                    return [2 /*return*/];
                }
                // Suggestions
                if (message && message.content.some(function (item) { var _a; return item.type === 'suggestions' && ((_a = item.suggestions) === null || _a === void 0 ? void 0 : _a.length) > 0; })) {
                    suggestions = message.content
                        .filter(function (item) { return item.type === 'suggestions'; }) // Ensure the type is suggestions
                        .flatMap(function (item) { return item.suggestions; });
                    // Add message
                    addMessage({ type: 'suggestions', suggestions: suggestions });
                    return [2 /*return*/];
                }
                ;
                sql_query = formatSQLQuery(message);
                if (sql_query != null) {
                    setSqlQuery(sql_query);
                    if (isOnySQLEnabled) {
                        processSQLQuery(sql_query, addMessage, promptId);
                    }
                    else {
                        processSQLResult(sql_result, sql_query, setColumns, addMessage);
                    }
                }
                return [3 /*break*/, 10];
            case 8:
                error_2 = _a.sent();
                alert("An error occurred. Please Refresh the page try again. If the issue persists, contact dune@cimpress.com");
                return [3 /*break*/, 10];
            case 9:
                setIsLoading(false); // Ensure loading state is reset
                return [7 /*endfinally*/];
            case 10: return [2 /*return*/];
        }
    });
}); };
var executeSQLStatement = function (accessToken, sql_query, setColumns, addMessage, setIsLoading, promptId) { return __awaiter(void 0, void 0, void 0, function () {
    var sql_result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                setIsLoading(true);
                return [4 /*yield*/, ApiService.executeSQL(accessToken, promptId)];
            case 1:
                sql_result = (_a.sent()).sql_result;
                processSQLResult(sql_result, sql_query, setColumns, addMessage);
                setIsLoading(false);
                return [2 /*return*/];
        }
    });
}); };
// Main application component
var ChatMCPComponent = function (props) {
    var _a = useState(''), prompt = _a[0], setPrompt = _a[1];
    var _b = useState(""), fileName = _b[0], setFileName = _b[1];
    var _c = useState([]), fileOptions = _c[0], setFileOptions = _c[1];
    var accessToken = props.accessToken;
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useState([]), columns = _e[0], setColumns = _e[1];
    var _f = useState([]), data = _f[0], setData = _f[1];
    var _g = useState(false), hasStartedChat = _g[0], setHasStartedChat = _g[1];
    var _h = useState([]), chatHistory = _h[0], setChatHistory = _h[1];
    var chatContainerRef = useRef(null);
    var _j = useState(0), loadingTime = _j[0], setLoadingTime = _j[1];
    var _k = useState(false), isCompleteEnabled = _k[0], setIsCompleteEnabled = _k[1];
    var _l = useState(false), isOnySQLEnabled = _l[0], setIsOnySQLEnabled = _l[1];
    var _m = useState(''), sqlQuery = _m[0], setSqlQuery = _m[1];
    var lastMessageRef = useRef(null);
    var _o = useState(''), filters = _o[0], setFilter = _o[1];
    var searchParams = new URLSearchParams(window.location.search);
    var _p = useState(searchParams.get("promptId")), promptId = _p[0], setPromptId = _p[1];
    useEffect(function () {
        if (promptId) {
            // Remove 'promptId' from the query parameters
            searchParams.delete("promptId");
            // Construct the new URL without promptId
            var newSearch = searchParams.toString();
            var newUrl = "".concat(window.location.pathname).concat(newSearch ? "?".concat(newSearch) : '');
            // Update the browser history
            console.log("newURL:", newUrl);
            window.history.replaceState(null, "", newUrl);
            // Clear the promptId state after updating URL
            setPromptId(null);
        }
    }, [promptId]);
    var addMessage = function (message) {
        setChatHistory(function (prevHistory) { return __spreadArray(__spreadArray([], prevHistory, true), [
            __assign(__assign({}, message), { id: uuidv4(), ref: lastMessageRef, sqlQuery: sqlQuery }),
        ], false); });
    };
    useEffect(function () {
        console.log(filters, "Filter in main");
    }, [filters]);
    useEffect(function () {
        var fetchFileNames = function () { return __awaiter(void 0, void 0, void 0, function () {
            var files, options, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!accessToken)
                            return [2 /*return*/]; // Exit early if there's no access token
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ApiService.fetchFileNames(accessToken)];
                    case 2:
                        files = _a.sent();
                        options = files;
                        setFileOptions(options); // Set the mapped options to the state
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        console.error('Error fetching file names:', error_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result, _a, PROMPT, SEMANTIC_FILE, SQL_QUERY, error_4;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(promptId && accessToken)) return [3 /*break*/, 9];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, , 8]);
                        return [4 /*yield*/, ApiService.getByPromptId(accessToken, promptId)];
                    case 2:
                        result = _c.sent();
                        if (!(result.length == 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, fetchFileNames()];
                    case 3:
                        _c.sent();
                        return [2 /*return*/];
                    case 4: return [4 /*yield*/, fetchFileNames()];
                    case 5:
                        _c.sent();
                        console.log("result by prompt id", (_b = result[0]) === null || _b === void 0 ? void 0 : _b.PROMPT); // Use optional chaining
                        if (result && result.length === 1) {
                            _a = result[0], PROMPT = _a.PROMPT, SEMANTIC_FILE = _a.SEMANTIC_FILE, SQL_QUERY = _a.SQL_QUERY;
                            setSqlQuery(SQL_QUERY);
                            // Add a message with the prompt as a message by the user
                            addMessage({ type: 'user', text: PROMPT });
                            // Fetch file names
                            setFileName(SEMANTIC_FILE.replace(".yaml", ""));
                            // Process the SQL query and add message
                            processSQLQuery(SQL_QUERY, addMessage, promptId);
                        }
                        return [3 /*break*/, 8];
                    case 6:
                        error_4 = _c.sent();
                        console.error('Error fetching data by promptId:', error_4);
                        return [4 /*yield*/, fetchFileNames()];
                    case 7:
                        _c.sent(); // Fetch file names in case of an error
                        return [3 /*break*/, 8];
                    case 8: return [3 /*break*/, 11];
                    case 9: return [4 /*yield*/, fetchFileNames()];
                    case 10:
                        _c.sent(); // Fetch file options if no promptId is provided
                        _c.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [promptId, accessToken]);
    var fetchChatHistory = function () { return __awaiter(void 0, void 0, void 0, function () {
        var history_1, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!accessToken || !fileName)
                        return [2 /*return*/]; // Ensure accessToken and fileName are available
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ApiService.fetchChatHistory(accessToken, fileName)];
                case 2:
                    history_1 = _a.sent();
                    // Update state with unique IDs and add 'pre_chat' type messages
                    history_1.forEach(function (item) {
                        var message = {
                            type: 'pre_chat',
                            text: item[0],
                            id: uuidv4(), // Generate a unique ID
                        };
                        addMessage(message); // Add the message to chat history
                    });
                    return [3 /*break*/, 4];
                case 3:
                    error_5 = _a.sent();
                    console.error('Error fetching chat history:', error_5);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!promptId) {
            setChatHistory(function (prevHistory) { return prevHistory.filter(function (message) { return message.type !== 'pre_chat'; }); });
            fetchChatHistory(); // Fetch chat history when fileName or accessToken changes
        }
    }, [fileName, accessToken]);
    // Handle form submission
    var handleSubmitWrapper = useCallback(function (event, promptData) {
        if (event === void 0) { event = null; }
        if (promptData === void 0) { promptData = ''; }
        if (event) {
            event.preventDefault();
        }
        setHasStartedChat(true);
        handleSubmit(promptData || prompt, fileName, accessToken, addMessage, setIsLoading, setColumns, setData, // Pass the state values to handleSubmit
        isCompleteEnabled, setIsCompleteEnabled, isOnySQLEnabled, setSqlQuery, filters);
        setPrompt('');
    }, [prompt, fileName, accessToken, filters, isCompleteEnabled, isOnySQLEnabled]);
    var executeSQLStatementWrapper = useCallback(function (event, promptIddata, promptData) {
        if (event === void 0) { event = null; }
        if (promptIddata === void 0) { promptIddata = ''; }
        if (promptData === void 0) { promptData = ''; }
        if (event) {
            event.preventDefault();
        }
        executeSQLStatement(accessToken, promptData || sqlQuery, setColumns, addMessage, setIsLoading, promptIddata || promptId || '');
    }, [accessToken, sqlQuery, promptId]);
    useEffect(function () {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [chatHistory]);
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'chat-mcp-wrapper' }, { children: _jsxs("div", __assign({ className: 'out-container', style: { display: 'flex', height: '90vh' } }, { children: [_jsx(ChatHeader, { isCompleteEnabled: isCompleteEnabled, setIsCompleteEnabled: setIsCompleteEnabled, isOnySQLEnabled: isOnySQLEnabled, setIsOnySQLEnabled: setIsOnySQLEnabled, columns: columns, setFilter: setFilter }), _jsxs("div", __assign({ className: "chat-app" }, { children: [_jsxs("div", __assign({ className: "chat-container", ref: chatContainerRef }, { children: [chatHistory.map(function (msg, index) { return (_jsx("div", __assign({ ref: index === chatHistory.length - 1 ? lastMessageRef : null }, { children: _jsx(ChatMessage, { message: msg, executeSQLStatement: executeSQLStatementWrapper, setPromptId: setPromptId, setSqlQuery: setSqlQuery, handleSubmitWrapper: handleSubmitWrapper }) }), msg.id)); }), isLoading && _jsx(LoadingMessage, { isLoading: isLoading })] })), _jsx(ChatForm, { handleSubmitWrapper: handleSubmitWrapper, prompt: prompt, fileName: fileName, setFileName: setFileName, fileOptions: fileOptions, isLoading: isLoading, setHasStartedChat: setHasStartedChat })] }))] })) })) }));
};
export default ChatMCPComponent;
