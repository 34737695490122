import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { AuthContext } from '../authContext';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Header from '@cimpress-technology/react-platform-header';
export var PlatformHeader = function () {
    var _a = React.useContext(AuthContext), accessToken = _a.accessToken, profile = _a.profile, logout = _a.logout, login = _a.login;
    var loggedIn = !!accessToken;
    var appLinks = [];
    return _jsx(Header, { appTitle: "ChatMCP", profile: profile, onLogInClicked: login, onLogOutClicked: logout, isLoggedIn: loggedIn, accessToken: accessToken });
};
