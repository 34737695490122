var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
var UserMessage = function (_a) {
    var text = _a.text, promptId = _a.promptId;
    var _b = useState(''), copyMessage = _b[0], setCopyMessage = _b[1];
    var handleCopy = function () {
        navigator.clipboard.writeText("".concat(window.location.origin, "/?promptId=").concat(promptId));
        setCopyMessage('Link copied!'); // Set the message when the link is copied
        setTimeout(function () { return setCopyMessage(''); }, 2000); // Clear the message after 2 seconds
    };
    return (_jsxs("div", __assign({ className: "user-message" }, { children: [_jsx("span", __assign({ className: "message-text" }, { children: text })), _jsx(LinkIcon, { className: "link-icon", onClick: handleCopy, titleAccess: "Copy URL", style: { cursor: 'pointer' } }), copyMessage && _jsx("span", __assign({ className: "copy-feedback" }, { children: copyMessage })), " "] })));
};
export default UserMessage;
